<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!--              <b-form-input
                              v-model="searchKey"
                              class="d-inline-block mr-1"
                              placeholder="搜索关键字..."
                            />-->



              <b-button
                  variant="primary"
                  :to="{ name: 'apps-allotoutbound-warehouse'}"
                  style="margin: 5px"
              >
                <span class="text-nowrap">返回</span>
              </b-button>
            </div>
          </b-col>

          <!-- Search -->
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-allotoutboundinbound-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <template #cell(inbound_no)="data" >
          <a target="_blank" :href="toURL(data.item)" style="color: deepskyblue"  v-if="data.item.status==2">
            {{data.item.inbound_no}}
          </a>
          <div v-if="data.item.status==1">
            {{data.item.inbound_no}}
          </div>
        </template>

        <!-- Column: Type -->
        <template #cell(status)="data">
          {{getCodeLabel('allot_status_intbound', data.item.status)}}
        </template>

        <template #cell(update_time)="data">
          {{toDate(data.item.update_time)}}
        </template>

        <template #cell(updator)="data">
          {{getCodeLabel('user', data.item.updator)}}
        </template>

        <template #cell(outbound_qty)="data">
           {{data.item.ext['outbound_qty']}}
        </template>

        <template #cell(inbound_qty)="data">
          {{data.item.ext['inbound_qty']}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
<!--单菜单-->
<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item v-if="data.item.status==1" :to="{ name: 'apps-allotoutboundinbound-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <!--装卸费-->
            <b-dropdown-item @click="showChargeModal(data.item,1)" v-if="data.item.status === 2">
              <feather-icon icon="ToolIcon"/>
              <span class="align-middle ml-50">生成装卸费</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
          id="pdfModal"
          centered
          title="PDF"
          ref="pdfModal"
          hide-footer
      >
        <attachment-upload theme="preview"
                           attachment_id="attachments"
                           :id="pdf_id"
                           :readonly="true"
                           v-if="pdf_id"
        />
        <div v-else>
          <p class="my-4">正在生成，请稍候!</p>
        </div>

      </b-modal>

      <fee-charge-select ref="feeRef" :object-type=4 :object-id=allotoutbound_inbound_id :warehouse-id=warehouse_id_to></fee-charge-select>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, toRefs} from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import allotoutboundinboundUseList from './allotoutboundinboundUseList'
import allotoutboundinboundStore from './allotoutboundinboundStore'
import {useRouter} from "@core/utils/utils";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {useToast} from "vue-toastification/composition";
import FeeChargeSelect from "@/views/apps/feeloadsetting/FeeChargeSelect";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    FeeChargeSelect
  },
  data() {
    return {
      allot_outbound_id:0,
      pdf_id:0,
      allotoutbound_inbound_id:0,
      warehouse_id_to:0,
    }
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('allotoutboundinbound/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('allotoutboundinbound')) store.registerModule('allotoutboundinbound', allotoutboundinboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutboundinbound')) store.unregisterModule('allotoutboundinbound')
    })

    const router = useRouter()

    const toast = useToast()

    const state = reactive({
      condition:{
      },
      curRow:undefined,
      rollbackModal:undefined,
      pdf_id:undefined,
      pdfModal:undefined,
    })

    const toURL = function (data){
      console.log(data.inbound_id)
      return "/apps/allotoutboundinbound/edit?id=" + data.inbound_id+"&isLook=1"

    }

    const showChargeModal = function (param,type){
      this.allotoutbound_inbound_id = param.id
      this.warehouse_id_to = param.towarehouse_id
      if(type === 1){
        this.$refs['feeRef'].showLoadChargeModal(param.towarehouse_id,4)
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = allotoutboundinboundUseList({
      allot_outbound_id: router.route.value.query.id}
    )

    return {
      ...toRefs(state),
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
      toURL,
      showChargeModal,
    }
  },

  created() {
    this.allot_outbound_id = this.$route.query.id || 0;
  }
}
</script>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
