import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function allotoutboundinboundUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    //{ key: 'id', label: 'ID', sortable: true },
    { key: 'inbound_no', label: '调拨入库编号', sortable: true },
    //{ key: 'outbound_id', label: '调拨出库单编号 allot_outbound_outbound.outbound_no', sortable: true },
    //{ key: 'outbound_no', label: '调拨出库单ID allot_outbound_outbound.outbound_id', sortable: true },
    //{ key: 'allot_outbound_id', label: '调拨单ID allot_outbound.outbound_id', sortable: true },
    //{ key: 'allot_outbound_no', label: '调拨单编号 allot_outbound.outbound_no', sortable: true },
    // { key: 'allot_type', label: '调拨类型  allot_type', sortable: true },
    // { key: 'duty_id', label: '责任人ID user表', sortable: true },
    // { key: 'duty_name', label: '责任人姓名', sortable: true },
    { key: 'outbound_qty', label: '出库数量'},
    { key: 'inbound_qty', label: '入库数量' },
    // { key: 'allot_time', label: '安排调拨日期', sortable: true },
    // { key: 'fromwarehouse_id', label: '调出仓库ID', sortable: true },
    // { key: 'fromwarehouse_name', label: '调出仓库名称', sortable: true },
    // { key: 'from_contact', label: '调出仓库联系人', sortable: true },
    // { key: 'from_mobile', label: '调出仓库联系电话', sortable: true },
    // { key: 'from_location', label: '调出仓库地址', sortable: true },
    // { key: 'towarehouse_id', label: '调入仓库ID', sortable: true },
    // { key: 'towarehouse_name', label: '调入仓库名称', sortable: true },
    // { key: 'to_contact', label: '调入仓库联系人', sortable: true },
    // { key: 'to_mobile', label: '调入仓库联系电话', sortable: true },
    // { key: 'to_location', label: '调入仓库地址', sortable: true },
    //{ key: 'memo', label: '备注', sortable: true },
   //{ key: 'attachments', label: '', sortable: true },
    { key: 'status', label: '入库单状态', sortable: true },
    //{ key: 'state', label: '启用', sortable: true },
    //{ key: 'create_time', label: '添加时间', sortable: true },
    //{ key: 'creator', label: '添加人ID', sortable: true },
    { key: 'update_time', label: '提交时间', sortable: true },
    { key: 'updator', label: '提交人', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('inbound_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('allotoutboundinbound/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        allot_outbound_id:config.allot_outbound_id,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
