<template>
  <div>
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="createFeeCharge"
        cancel-title="取消"
        centered
        size="lg"
        title="手工生成结算单"
        ref="feeChargeModal"
    >
      <b-col md="12">
        <b-row>
          <b-col md="6" v-if="feeChargeType === 1">
            <modal-select
                label="装卸队*"
                type="input"
                v-on:change="fromChildren($event,['company_name','company_id'])"
                :params="['company_name_load','company_id_load']"
                :value="company_name_load"
                modalName="装卸队"
                :warehouseId="warehouseId"
                :setting_ban_type="objectType"
                placeholder="点击选择装卸队"
            >
            </modal-select>
          </b-col>

          <b-col md="6" v-if="feeChargeType === 2">
            <modal-select
                label="承运商*"
                type="input"
                v-on:change="fromChildrenTransport($event,['company_name','company_id'])"
                :params="['company_name_transport','company_id_transport']"
                :value="company_name_transport"
                modalName="承运商"
                :show-all-logistics="false"
                :warehouse-id-to="warehouseIdTo"
                :warehouse-id-from="warehouseIdFrom"
                :setting_ban_type="objectType"
                placeholder="点击选择承运商"
            >
            </modal-select>
          </b-col>
        </b-row>
      </b-col>
    </b-modal>
  </div>

</template>


<script>

import XyGroup from "@/views/components/xy/XyGroup";
import flatPickr from "vue-flatpickr-component";
import {
  BButton,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  VBModal
} from "bootstrap-vue";
import vSelect from "vue-select";
import CompanySelect from "@/views/apps/company/CompanySelect";
import ModalSelect from "@/views/components/modal/ModalSelect";
import axios from '@axios';
import {useToast} from "vue-toastification/composition";
import toasts from "@/views/components/toasts/Toasts";
import {isEmpty} from "@core/utils/filter";

export default {
  components: {
    XyGroup,
    flatPickr,
    BInputGroup,
    VBModal,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    vSelect,
    CompanySelect,
    ModalSelect
  },
  data() {
    return {
      company_name_load: '',
      company_id_load: '',
      company_name_transport: '',
      company_id_transport: '',
      feeChargeType: 0,
    }
  },
  props: {
    warehouseId: {
      type: Number,
      default: 0
    },
    warehouseIdFrom: {
      type: Number,
      default: 0
    },
    warehouseIdTo: {
      type: Number,
      default: 0
    },
    showAllLogistics: {
      type: Boolean,
      default: true
    },
    objectType: {
      type: Number,
      default: 0
    },
    objectId: {
      type: Number,
      default: 0
    },
  },
  methods: {
    showLoadChargeModal(warehouseId,type) {
      this.feeChargeType = 1
      //查询装卸队
      axios.post('/api/company/searchLoadTeam', {
        'showAllLogistics': false, 'warehouseId': warehouseId,'setting_ban_type':type
      }).then(res => {
        if (res.data.code === 0) {
          const list = res.data.data.list
          if (list.length > 0) {
            this.company_name_load = list[list.length - 1].company_name
            this.company_id_load = list[list.length - 1].company_id
          } else {
            this.company_name_load = ''
            this.company_id_load = ''
          }
          this.$forceUpdate()
        }
      })
      this.$refs['feeChargeModal'].show()
    },
    showTransportChargeModal(warehouseIdFrom,warehouseIdTo,type) {
      this.feeChargeType = 2
      //查询承运商
      axios.post('/api/company/searchLogistics', {
        'showAllLogistics': false, 'warehouseIdFrom': warehouseIdFrom, 'warehouseIdTo': warehouseIdTo,'setting_ban_type':type
      }).then(res => {
        if (res.data.code === 0) {
          const list = res.data.data.list
          if (list.length > 0) {
            this.company_name_transport = list[list.length - 1].company_name
            this.company_id_transport = list[list.length - 1].company_id
          } else {
            this.company_name_transport = ''
            this.company_id_transport = ''
          }
          this.$forceUpdate()
        }
      })
      this.$refs['feeChargeModal'].show()
    }
  },
  setup(props) {
    const toast = useToast()

    const fromChildren = function (params) {
      this.company_name_load = params == null ? null : params['company_name_load']
      this.company_id_load = params == null ? null : params['company_id_load']
      this.$forceUpdate()
    }

    const fromChildrenTransport = function (params) {
      this.company_name_transport = params == null ? null : params['company_name_transport']
      this.company_id_transport = params == null ? null : params['company_id_transport']
      this.$forceUpdate()
    }


    const createFeeCharge = function () {
      if(this.feeChargeType === 1 && isEmpty(this.company_id_load)){
        toast.error('请选择装卸队')
        return
      }
      if(this.feeChargeType === 2 && isEmpty(this.company_id_transport)){
        toast.error('请选择承运商')
        return
      }
      axios.post('/api/feeloadcharge/createFeeCharge', {
        'feeChargeType': this.feeChargeType,
        'objectType': props.objectType,
        'objectId': props.objectId,
        'companyNameLoad': this.company_name_load,
        'companyIdLoad': this.company_id_load,
        'companyNameTransport': this.company_name_transport,
        'companyIdTransport': this.company_id_transport,
      }).then(res => {
        if (res.data.code === 0) {
          toast.success('已生成!')
        } else {
          toast.error(res.data.data)
        }
      })
    }

    return {
      fromChildren,
      fromChildrenTransport,
      createFeeCharge,
    }
  },
}
</script>
